var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { width: "720", title: "选择帖子" },
          on: { "on-ok": _vm.cursorSelect },
          model: {
            value: _vm.showSelectAuction,
            callback: function ($$v) {
              _vm.showSelectAuction = $$v
            },
            expression: "showSelectAuction",
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("Input", {
                staticClass: "w200",
                attrs: { placeholder: "版块名称" },
                on: { "on-enter": _vm.search },
                model: {
                  value: _vm.groupName,
                  callback: function ($$v) {
                    _vm.groupName = $$v
                  },
                  expression: "groupName",
                },
              }),
              _vm._v(" "),
              _c(
                "Select",
                {
                  staticClass: "w200 ml20",
                  attrs: { placeholder: "请选择类型" },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("Option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "1" } }, [_vm._v("短视频")]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "2" } }, [_vm._v("动态")]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "3" } }, [_vm._v("鉴定")]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "4" } }, [_vm._v("文章")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.search($event)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h400 mt20",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("td", { attrs: { width: "40%", align: "center" } }, [
                        _vm._v("帖子内容"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("帖子信息"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("发帖人"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "10%", align: "center" } }, [
                        _vm._v("板块"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          m.feed_type == 2
                            ? _c("div", [
                                m.img_list.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "item-flex-center" },
                                      [
                                        _c("img", {
                                          staticClass: "img",
                                          attrs: {
                                            src: m.img_list[0] + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "item-flex-center" }, [
                                  m.update_time
                                    ? _c("div", [_vm._v(_vm._s(m.update_time))])
                                    : _vm._e(),
                                ]),
                              ])
                            : m.feed_type == 4
                            ? _c("div", [
                                _c("div", { staticClass: "item-flex-center" }, [
                                  _vm._v(_vm._s(m.title)),
                                ]),
                                _vm._v(" "),
                                m.update_time
                                  ? _c("div", [_vm._v(_vm._s(m.update_time))])
                                  : _vm._e(),
                              ])
                            : m.feed_type == 1 || m.feed_type == 3
                            ? _c("div", [
                                m.video_content.coverImage
                                  ? _c(
                                      "div",
                                      { staticClass: "item-flex-center" },
                                      [
                                        _c("img", {
                                          staticClass: "img",
                                          staticStyle: {
                                            width: "auto",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: m.video_content.coverImage,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "item-flex-center" }, [
                                  m.update_time
                                    ? _c("div", [_vm._v(_vm._s(m.update_time))])
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", { staticClass: "item-flex-center" }, [
                              _vm._v(
                                "\n                  帖子ID：" +
                                  _vm._s(m.id) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            m.feed_type == 2
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "item-flex-center" },
                                    [_vm._v(_vm._s(m.img_list.length) + "图")]
                                  ),
                                ])
                              : m.feed_type == 1 || m.feed_type == 3
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "item-flex-center" },
                                    [_vm._v("1视频")]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", { staticClass: "item-flex-center" }, [
                              m.avatar
                                ? _c("img", {
                                    staticClass: "img",
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px",
                                      "border-radius": "50%",
                                      "margin-right": "5px",
                                    },
                                    attrs: {
                                      src:
                                        m.avatar +
                                        (m.avatar.indexOf(
                                          "https://img.yunzongbu.cn"
                                        ) != -1
                                          ? "!120a"
                                          : ""),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-one" }, [
                                _c("div", [_vm._v(_vm._s(m.uname))]),
                                _vm._v(" "),
                                _c("div", [_vm._v("ID：" + _vm._s(m.uid))]),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", { staticClass: "item-flex-center" }, [
                              _vm._v(_vm._s(m.group_name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-flex-center" }, [
                              _vm._v("ID：" + _vm._s(m.group_id)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.id) == -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("Page", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.queryParams.size,
                  "show-sizer": "",
                  "show-total": "",
                  "show-elevator": "",
                  current: _vm.queryParams.page,
                },
                on: {
                  "on-page-size-change": _vm.changePageSize,
                  "on-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }