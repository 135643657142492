var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-ueditor-wrap", {
        staticStyle: { width: "90%" },
        attrs: { config: _vm.myConfig },
        on: { beforeInit: _vm.addCustomDialog, ready: _vm.ready },
        model: {
          value: _vm.contents,
          callback: function ($$v) {
            _vm.contents = $$v
          },
          expression: "contents",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }