"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutolabelCreateApi = AutolabelCreateApi;
exports.AutolabelDeleteApi = AutolabelDeleteApi;
exports.AutolabelLstApi = AutolabelLstApi;
exports.AutolabelSyncApi = AutolabelSyncApi;
exports.AutolabelUpdateApi = AutolabelUpdateApi;
exports.addTagApi = addTagApi;
exports.auctionRadio = auctionRadio;
exports.authorCommisionList = authorCommisionList;
exports.authorCreateApi = authorCreateApi;
exports.authorDelApi = authorDelApi;
exports.authorListApi = authorListApi;
exports.authorUpdateApi = authorUpdateApi;
exports.batchChangeGroupApi = batchChangeGroupApi;
exports.captchaApi = captchaApi;
exports.changeGroupApi = changeGroupApi;
exports.checkUserIdCardImage = checkUserIdCardImage;
exports.consumeCreate = consumeCreate;
exports.consumeDel = consumeDel;
exports.consumeEdit = consumeEdit;
exports.consumeGetInfoById = consumeGetInfoById;
exports.consumeGetTopList = consumeGetTopList;
exports.createUserApi = createUserApi;
exports.createUserForm = createUserForm;
exports.createVerifyApi = createVerifyApi;
exports.editFormApi = editFormApi;
exports.editVerifyApi = editVerifyApi;
exports.exportAuthorCommision = exportAuthorCommision;
exports.getAllList = getAllList;
exports.getBaseInfo = getBaseInfo;
exports.getConsumeTopInfo = getConsumeTopInfo;
exports.getInfo = getInfo;
exports.getMenusApi = getMenusApi;
exports.getSmsCode = getSmsCode;
exports.getUpGradeInfoApi = getUpGradeInfoApi;
exports.getUserDetail = getUserDetail;
exports.getUserNumApi = getUserNumApi;
exports.gradeDeleteApi = gradeDeleteApi;
exports.gradeInfoApi = gradeInfoApi;
exports.gradeLevelListApi = gradeLevelListApi;
exports.gradeSaveApi = gradeSaveApi;
exports.gradeUpdateApi = gradeUpdateApi;
exports.groupDeleteApi = groupDeleteApi;
exports.groupEditApi = groupEditApi;
exports.groupFormApi = groupFormApi;
exports.groupLstApi = groupLstApi;
exports.inviteExport = inviteExport;
exports.inviteGetInviteListApi = inviteGetInviteListApi;
exports.inviteListApi = inviteListApi;
exports.inviteRuleAddNewRuleApi = inviteRuleAddNewRuleApi;
exports.inviteRuleDeleteApi = inviteRuleDeleteApi;
exports.inviteRuleInfoApi = inviteRuleInfoApi;
exports.inviteRuleUpdateApi = inviteRuleUpdateApi;
exports.inviteSetListApi = inviteSetListApi;
exports.inviteSetSeeApi = inviteSetSeeApi;
exports.inviteSetUpdateApi = inviteSetUpdateApi;
exports.inviteToBuyListApi = inviteToBuyListApi;
exports.inviteWithDrawListApi = inviteWithDrawListApi;
exports.issueIntegralApi = issueIntegralApi;
exports.issuerCreateApi = issuerCreateApi;
exports.issuerDelApi = issuerDelApi;
exports.issuerListApi = issuerListApi;
exports.issuerUpdateApi = issuerUpdateApi;
exports.labelAddApi = labelAddApi;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelLstApi = labelLstApi;
exports.login = login;
exports.loginConfigApi = loginConfigApi;
exports.logout = logout;
exports.memberInfoExport = memberInfoExport;
exports.passwordFormApi = passwordFormApi;
exports.payauctionRadio = payauctionRadio;
exports.putUpGradeInfoApi = putUpGradeInfoApi;
exports.registercodeCreatedApi = registercodeCreatedApi;
exports.registercodeDelApi = registercodeDelApi;
exports.registercodeExportApi = registercodeExportApi;
exports.registercodeGroupList = registercodeGroupList;
exports.registercodeListApi = registercodeListApi;
exports.setRegisterCodeStatus = setRegisterCodeStatus;
exports.switchLockTypeApi = switchLockTypeApi;
exports.switchUserStatusApi = switchUserStatusApi;
exports.toUserScoreOrCoupon = toUserScoreOrCoupon;
exports.unlockCityApi = unlockCityApi;
exports.unlockOrderAllApi = unlockOrderAllApi;
exports.unlockOrderApi = unlockOrderApi;
exports.updateUserForm = updateUserForm;
exports.upgradeList = upgradeList;
exports.upgradeReject = upgradeReject;
exports.upgradeRejectSure = upgradeRejectSure;
exports.userBidAllstatics = userBidAllstatics;
exports.userCouponApi = userCouponApi;
exports.userLookRadio = userLookRadio;
exports.userLstApi = userLstApi;
exports.userOrderApi = userOrderApi;
exports.userUpAuctionAllstatics = userUpAuctionAllstatics;
exports.userUpAuctionList = userUpAuctionList;
exports.userVerifyApi = userVerifyApi;
exports.userVerifyListApi = userVerifyListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 验证码
 */
function captchaApi() {
  return _request.default.get("auth/captchaImage");
}
/**
 * @description 登录
 */
function login(data) {
  return _request.default.post("auth/login", data);
}
/**
 * @description 登录页配置
 */
function loginConfigApi() {
  return _request.default.get("login_config");
}

/**
 * @description 退出登录
 */
function logout() {
  return _request.default.delete("auth/logout");
}

/**
 * @description 发行方 - 列表
 */
function issuerListApi(data) {
  return _request.default.get("product/sys/issuer/list", data);
}
/**
 * @description 发行方 - 创建
 */
function issuerCreateApi(data) {
  return _request.default.post("product/sys/issuer/save", data);
}

/**
 * @description 发行方 - 修改
 */
function issuerUpdateApi(data) {
  return _request.default.put("product/sys/issuer/update", data);
}
/**
 * @description 发行方 - 删除
 */
function issuerDelApi(id) {
  return _request.default.delete("product/sys/issuer/delete/".concat(id));
}

/**
 * @description 作者 - 列表
 */
function authorListApi(data) {
  return _request.default.get("product/sys/author/list", data);
}
/**
 * @description 作者 - 创建
 */
function authorCreateApi(data) {
  return _request.default.post("product/sys/author/save", data);
}

/**
 * @description 作者 - 修改
 */
function authorUpdateApi(data) {
  return _request.default.put("/product/sys/author/update", data);
}
/**
 * @description 作者 - 删除
 */
function authorDelApi(id) {
  return _request.default.post("/product/sys/author/delete/".concat(id));
}

/**
 * @description 修改密码
 */
function passwordFormApi() {
  return _request.default.get("system/admin/edit/password/form");
}

/**
 * @description 修改自己的信息
 */
function editFormApi() {
  return _request.default.get("system/admin/edit/form");
}
/**
 * @description 菜单
 */
function getMenusApi() {
  return _request.default.get("menus");
}
function getInfo(token) {
  return (0, _request.default)({
    url: "/vue-element-admin/user/info",
    method: "get",
    params: {
      token: token
    }
  });
}
/**
 * @description 标签
 */
function getBaseInfo() {
  return _request.default.get("info");
}
/**
 * @description 用户标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get("member/sys/member/tag/list", data);
}
/**
 * @description 用户标签 -- 添加表单
 */
function labelAddApi(data) {
  return _request.default.post("member/sys/member/tag/save", data);
}
/**
 * @description 用户标签 -- 编辑表单
 */
function labelEditApi(data) {
  return _request.default.post("/member/sys/member/tag/update", data);
}
/**
 * @description 用户标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.post("member/sys/member/tag/delete/".concat(id));
}
/**
 * @description 自动标签 -- 添加
 */
function AutolabelCreateApi(data) {
  return _request.default.post("auto_label/create", data);
}
/**
 * @description 自动标签 -- 列表
 */
function AutolabelLstApi(data) {
  return _request.default.get("auto_label/lst", data);
}
/**
 * @description 自动标签 -- 编辑
 */
function AutolabelUpdateApi(id, data) {
  return _request.default.post("auto_label/update/" + id, data);
}
/**
 * @description 自动标签 -- 删除
 */
function AutolabelDeleteApi(id) {
  return _request.default.delete("auto_label/delete/".concat(id));
}
/**
 * @description 自动标签 -- 更新
 */
function AutolabelSyncApi(id) {
  return _request.default.post("auto_label/sync/" + id);
}
/**
 * @description 用户 -- 列表
 */
function userLstApi(data) {
  return _request.default.get("member/sys/member/info/allList", data);
}

/**
 * @description 新用户 -- 列表
 */
function getAllList(data) {
  return _request.default.get("/member/sys/member/info/selectUserByPhone", data);
}

/**
 * @description 新增用户
 */
function createUserApi(data) {
  return _request.default.post("member/sys/member/info/add", data);
}
/**
 * @description 单个加签、批量加签
 */
function addTagApi(data) {
  return _request.default.post("member/sys/member/info/addTag", data);
}
/**
 * @description 用户 -- 详情消费记录
 */
function userOrderApi(uid, data) {
  return _request.default.get("user/order/".concat(uid), data);
}
/**
 * @description 用户 -- 详情优惠券
 */
function userCouponApi(uid, data) {
  return _request.default.get("user/coupon/".concat(uid), data);
}
/**
 * @description 用户 -- 批量设置分组
 */
function changeGroupApi(id) {
  return _request.default.get("user/change_label/form/" + id);
}

/**
 * @description 创建用户
 */
function createUserForm(data) {
  return _request.default.get("user/createForm");
}
/**
 * @description 修改用户
 */
function updateUserForm(id) {
  return _request.default.get("user/createForm");
}
/**
 * @description 获取用户详情
 */
function getUserDetail(data) {
  return _request.default.get("member/sys/member/info/details", data);
}
/**
 * @description 用户分组 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function groupEditApi(id) {
  return _request.default.get("user/group/form/" + id);
}
/**
 * @description 用户分组 -- 添加表单
 */
function groupFormApi() {
  return _request.default.get("user/group/form");
}
/**
 * @description 用户分组 -- 列表
 */
function groupLstApi(data) {
  return _request.default.get("user/group/lst", data);
}
/**
 * @description 用户分组 -- 删除
 */
function groupDeleteApi(id) {
  return _request.default.delete("user/group/".concat(id));
}
/**
 * @description 用户列表 -- 批量设置分组
 */
function batchChangeGroupApi(data) {
  return _request.default.get("user/batch_change_group/form", data);
}
/**
 * @description 用户参拍统计
 */
function userBidAllstatics(id) {
  return _request.default.get("user/statistical/bidRecordData/".concat(id));
}
/**
 * @description 用户送拍记录统计-委托标的清单
 */
function userUpAuctionAllstatics(id) {
  return _request.default.get("user/statistical/entrustProductData/".concat(id));
}
/**
 * @description 用户送拍记录列表
 */
function userUpAuctionList(data) {
  return _request.default.get("erp/entrust/order/uidByList", data);
}
/**
 * @description 用户浏览拍品类目占比
 */
function userLookRadio(id) {
  return _request.default.get("user/statistical/visitAuctionCategoryChartsData/".concat(id));
}
/**
 * @description 用户参拍类目占比
 */
function auctionRadio(id) {
  return _request.default.get("user/statistical/bidRecordCategoryChartsData/".concat(id));
}
/**
 * @description 用户成交类目占比
 */
function payauctionRadio(id) {
  return _request.default.get("user/statistical/dealCategoryChartsData/".concat(id));
}
/**
 * @description 用户审核列表
 */
function userVerifyListApi(data) {
  return _request.default.get("member/sys/member/verified/list", data);
}
/**
 * @description 用户审核
 */
function userVerifyApi(data) {
  return _request.default.post("member/sys/member/verified/audit", data);
}

/**
 * 身份证照片校验
 */
function checkUserIdCardImage(data) {
  return _request.default.post("user/checkUserIdCardImage", data);
}

/**
 * 修改用户状态 0 禁用  1正常
 */
function switchUserStatusApi(data) {
  return _request.default.post("member/sys/member/info/disable", data);
}
/**
 * 解锁
 */
function switchLockTypeApi(data) {
  return _request.default.post("member/sys/member/info/lockType", data);
}

/**
 * 获取用户数量
 */
function getUserNumApi(data) {
  return _request.default.get("system/sms/getUserNum", data);
}

/**
 * 创建实名认证
 */
function createVerifyApi(data) {
  return _request.default.post("member/sys/member/verified", data);
}

/**
 * 修改实名认证
 */
function editVerifyApi(data) {
  return _request.default.post("user/verify/edit", data);
}

/**
 * 解锁
 */
function unlockOrderApi(data) {
  return _request.default.post("user/unlockOrder", data);
}

/**
 * 全部解锁
 */
function unlockOrderAllApi(data) {
  return _request.default.post("user/unlockOrderAll", data);
}

/**
 * 发放积分
 */
function issueIntegralApi(data) {
  return _request.default.post("UserIntegral/issueIntegral", data);
}

/**
 * 邀请列表
 */
function inviteListApi(data) {
  return _request.default.get("member/sys/member/invite/list", data);
}

/**
 * 被邀请人列表、已实名列表
 */
function inviteGetInviteListApi(data) {
  return _request.default.get("member/sys/member/invite/getInviteeList", data);
}

/**
 * 用户邀请 分佣购买记录
 */
function inviteToBuyListApi(data) {
  return _request.default.get("member/sys/member/invite/getToBuyList", data);
}

/**
 * 邀请列表里的提现记录
 */
function inviteWithDrawListApi(data) {
  return _request.default.get("member/sys/member/invite/withDrawInfo", data);
}

/**
 * 查看邀请设置
 */
function inviteSetSeeApi(data) {
  return _request.default.get("member/sys/member/inviteSet/see", data);
}

/**
 * 查询邀请等级设置详情
 */
function inviteRuleInfoApi(data) {
  return _request.default.get("member/sys/member/inviterule/info", data);
}

/**
 * 修改分享海报、或分佣状态
 */
function inviteSetUpdateApi(data) {
  return _request.default.post("member/sys/member/inviteSet/update", data);
}

/**
 * 查询邀请等级设置列表
 */
function inviteSetListApi(data) {
  return _request.default.get("member/sys/member/inviterule/list", data);
}

/**
 * 添加邀请等级设置
 */
function inviteRuleAddNewRuleApi(data) {
  return _request.default.post("member/sys/member/inviterule/addNewRule", data);
}

/**
 * 修改邀请等级设置
 */
function inviteRuleUpdateApi(data) {
  return _request.default.put("member/sys/member/inviterule/update", data);
}

/**
 * 删除邀请等级设置
 */
function inviteRuleDeleteApi(id) {
  return _request.default.post("member/sys/member/inviterule/delete/".concat(id));
}

/**
 * 获取用户等级列表
 */
function gradeLevelListApi(data) {
  return _request.default.get("member/sys/member/level/list", data);
}

/**
 * 创建用户等级
 */
function gradeSaveApi(data) {
  return _request.default.post("member/sys/member/level/save", data);
}

/**
 * 修改用户等级
 */
function gradeUpdateApi(data) {
  return _request.default.put("member/sys/member/level/update", data);
}

/**
 * 获取用户等级详情
 */
function gradeInfoApi(id) {
  return _request.default.get("member/sys/member/level/".concat(id));
}

/**
 * 删除用户等级详情
 */
function gradeDeleteApi(id) {
  return _request.default.delete("member/sys/member/level/".concat(id));
}

/**
 * 获取用户等级升级任务
 */
function getUpGradeInfoApi() {
  return _request.default.get("member/sys/member/level/upgrade/info");
}

/**
 * 修改用户等级升级任务
 */
function putUpGradeInfoApi(data) {
  return _request.default.put("member/sys/member/level/upgrade/update", data);
}

/**
 * 用户管理导出
 */
function memberInfoExport(data) {
  return _request.default.get("member/sys/member/info/memberInfoExport", data);
}

/**
 * 领取积分
 */
function toUserScoreOrCoupon(data) {
  return _request.default.post("member/sys/member/info/toUserScoreOrCoupon", data);
}

/**
 * 获取注销申请列表 /member/sys/member/level/upgrade/list /sys/member/logoff
 */
function upgradeList(data) {
  return _request.default.get("member/sys/member/logoff/list", data);
}
/**
 * 注销申请驳回注销 /member/sys/member/level/upgrade/reject/{id}
 */

function upgradeReject(data) {
  return _request.default.get("member/sys/member/logoff/reject/".concat(data.id), data);
}

/**
 * 确认注销  /member/sys/member/level/upgrade/confirm/{id}
 */
function upgradeRejectSure(data) {
  return _request.default.post("/member/sys/member/logoff/confirm", data);
}

/**
 * 查看消费排行统计 /member/sys/member/consume/getConsumeTopInfo
 */
function getConsumeTopInfo(data) {
  return _request.default.get("member/sys/member/consume/getConsumeTopInfo", data);
}

/**
 * 新增消费排行  /member/sys/member/consume/create
 */
function consumeCreate(data) {
  return _request.default.post("member/sys/member/consume/create", data);
}
/**
 * 查看详情   /member/sys/member/consume/getInfoById/{id}
 */
function consumeGetInfoById(data) {
  return _request.default.post("member/sys/member/consume/getInfoById/".concat(data.id), data);
}

/**
 * 编辑  /member/sys/member/consume/edit
 */
function consumeEdit(data) {
  return _request.default.post("member/sys/member/consume/edit", data);
}
/**
 * 删除 /member/sys/member/consume/del/{id}
 */
function consumeDel(data) {
  return _request.default.post("member/sys/member/consume/del/".concat(data.id), data);
}

/**
 * 查看排行榜 /member/sys/member/consume/getTopList
 */
function consumeGetTopList(data) {
  return _request.default.get("member/sys/member/consume/getTopList", data);
}

/**
 * 设置注册码开关
 */
function setRegisterCodeStatus(data) {
  return _request.default.put("/member/sys/register/code/edit", data);
}

/**
 * 注册码组列表
 */
function registercodeGroupList(data) {
  return _request.default.get("/member/sys/register/code/list", data);
}

/**
 * 创建注册码 输入数量
 */
function registercodeCreatedApi(data) {
  return _request.default.post("/member/sys/register/code/create", data);
}

/**
 * 删除注册码组
 */
function registercodeDelApi(id) {
  return _request.default.delete("/member/sys/register/code/del/".concat(id));
}

/**
 * 查看注册码列表
 */
function registercodeListApi(data) {
  return _request.default.get("/member/sys/register/code/codeInfo", data);
}

/**
 * 导出注册码
 */
function registercodeExportApi(data) {
  return _request.default.get("/member/sys/register/code/export", data);
}
// 获取作者分佣接口
function authorCommisionList(data) {
  return _request.default.get("/order/sys/order/authorCommonOrderList", data);
}
// 导出作者分佣接口
function exportAuthorCommision(data) {
  return _request.default.get("/order/sys/order/exportAuthorCommonOrderList", data);
}

//导出邀请列表
function inviteExport(data) {
  return _request.default.get("/member/sys/member/invite/export", data);
}

//解除城市锁定
function unlockCityApi(id) {
  return _request.default.post("/member/sys/member/info/unlockCity/".concat(id));
}

// 获取短信验证码
function getSmsCode(data) {
  return _request.default.get("/resource/sms/code/for/sys", data);
}